import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { BehaviorSubject, EMPTY, Observable, Subject, throwError } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';

import { GetToken, ProblemDetails } from '@api-open';
import { AlertifyService } from '@shared/services';
import { AuthService } from '@shared/services/auth.service';

import { BYPASS_ERROR_400 } from './bypass-error.context-token';
import { ErrorResponseWithStatus, PASS_STATUS_CODE } from './bypass-status.context-token';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private updateTokensInProgress$ = new BehaviorSubject(false);
  private updateTokenProcessFinished$ = new Subject<boolean>();

  constructor(
    private authService: AuthService,
    private alertify: AlertifyService,
  ) {}

  refreshTokens(): Observable<GetToken> {
    return this.authService
      .refreshToken({
        token: this.authService.getToken(),
        refreshToken: this.authService.getRefreshToken(),
      })
      .pipe(
        tap((token) => {
          this.authService.storeTokens(token);
        }),
      );
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorBody = error.error;

        if (request.responseType === 'text') {
          try {
            errorBody = JSON.parse(errorBody);
          } catch {
            /* empty */
          }
        }

        function throwResponseError(message: string) {
          return throwError(new ResponseErrorMessage(message, request, error));
        }

        switch (error.status) {
          case 403:
            if (error.headers.get('token-expired') === 'true') {
              this.logout();
            }
            this.updateTokensInProgress$
              .pipe(
                take(1),
                filter((isLoading) => !isLoading),
                tap(() => {
                  this.alertify.informative(errorBody.detail);
                  return this.updateTokensInProgress$.next(true);
                }),
                switchMap(() => this.refreshTokens()),
                catchError(() => {
                  this.logout();
                  return EMPTY;
                }),
              )
              .subscribe((authData) => {
                if (!authData) {
                  this.logout();
                }
                this.logError(request, error);
                this.alertify.informative('The page will be reloaded in 2 seconds');

                setTimeout(() => {
                  window.location.reload();
                }, 2000);
              });

            return EMPTY;
          case 401:
            if (error.headers.get('token-expired') === 'true') {
              this.updateTokensInProgress$
                .pipe(
                  take(1),
                  filter((isLoading) => !isLoading),
                  tap(() => this.updateTokensInProgress$.next(true)),
                  switchMap(() => this.refreshTokens()),
                  catchError(() => {
                    this.logout();
                    return EMPTY;
                  }),
                )
                .subscribe(
                  (authData) => {
                    if (!authData) {
                      this.logout();
                    }
                    this.updateTokensInProgress$.next(false);
                    this.updateTokenProcessFinished$.next(true);
                  },
                  () => {
                    this.updateTokensInProgress$.next(false);
                    this.updateTokenProcessFinished$.next(true);
                    this.logout();
                  },
                );

              return this.updateTokenProcessFinished$.pipe(
                take(1),
                filter((tokenSuccessfullyUpdated) => tokenSuccessfullyUpdated),
                switchMap(() => {
                  const updatedRequest = request.clone({
                    headers: request.headers.set('Authorization', 'Bearer ' + this.authService.getToken()),
                  });
                  return next
                    .handle(updatedRequest)
                    .pipe(catchError((errorResponse: HttpErrorResponse) => throwResponseError(errorResponse.message)));
                }),
              );
            }
            this.logout();
            return throwResponseError(error.message);
          case 500:
            this.logError(request, error);
            return throwResponseError('500 - Server error');
          case 400:
            if (!request.context.get(BYPASS_ERROR_400)) {
              this.logError(request, error);
            }
            return throwResponseError(errorBody?.detail || error.message || 'Error occurred');
          default:
            return throwResponseError(errorBody?.detail || error.message || 'Error occurred');
        }
      }),
      catchError(({ request, message, errorResponse }: ResponseErrorMessage) => {
        if (request.context.get(PASS_STATUS_CODE)) {
          const errorWithStatus: ErrorResponseWithStatus = { message, status: errorResponse.status };
          return throwError(errorWithStatus);
        }
        return throwError(message);
      }),
    );
  }

  private logout(): void {
    this.authService.logout({ saveLocationForRedirect: true });
  }

  private logError(request: HttpRequest<unknown>, errorResponse: HttpErrorResponse): void {
    const responseMessage = this.getErrorResponseMessage(errorResponse);
    const responseError = new ResponseError(responseMessage, request.url, request.method, errorResponse.status);

    Sentry.withScope(function httpInterceptorScope(scope) {
      const fingerprint = [request.method, request.url, `${errorResponse.status}`];
      scope.setFingerprint(fingerprint);
      Sentry.captureException(responseError, { syntheticException: responseError, originalException: errorResponse });
    });
  }

  private getErrorResponseMessage(errorResponse: HttpErrorResponse): string {
    const errorDetails: ProblemDetails | string | undefined = errorResponse.error;

    if (this.isProblemDetails(errorDetails) && errorDetails.detail) {
      return errorDetails.detail;
    }

    if (typeof errorDetails === 'string') {
      return errorDetails;
    }

    return errorResponse.message;
  }

  private isProblemDetails(errorBody: ProblemDetails | unknown): errorBody is ProblemDetails {
    return !!errorBody && !!errorBody['detail'];
  }
}

class ResponseError extends Error {
  constructor(
    readonly response: string,
    readonly url: string,
    readonly method: string,
    readonly statusCode: number,
  ) {
    super(`${method} ${url} - ${statusCode}\n\n${response}`);
  }
}

class ResponseErrorMessage extends Error {
  constructor(
    override message: string,
    readonly request: HttpRequest<unknown>,
    readonly errorResponse: HttpErrorResponse,
  ) {
    super(message);
  }
}
